import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { AuthorizationConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AuthorizationCheckService } from './api/authorization-check.service';
import { ClaimsCacheService } from './api/claims-cache.service';
import { PublicKeyService } from './api/public-key.service';
import { TokenService } from './api/token.service';
import { WellKnownService } from './api/well-known.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class AuthorizationApiModule {
    public static forRoot(configurationFactory: () => AuthorizationConfiguration): ModuleWithProviders<AuthorizationApiModule> {
        return {
            ngModule: AuthorizationApiModule,
            providers: [ { provide: AuthorizationConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: AuthorizationApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('AuthorizationApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
